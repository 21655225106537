/*
  ** アイコンフォント生成
  https://icomoon.io/app/#/select

  ** 追加する場合
  https://qiita.com/m-nakamura/items/abc871b1da6cfaf4db42
*/
i[class^="c-icon-"] {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* arrows-outline */
@font-face {
  font-family: 'icon-arrows-outline';
  src:
    url('./arrows-outline/icomoon.ttf') format('truetype'),
    url('./arrows-outline/icomoon.woff') format('woff'),
    url('./arrows-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-arrows"] {
  font-family: 'icon-arrows-outline' !important;
}

.c-icon-outline-arrows-chevron-down:before { content: "\d450"; }
.c-icon-outline-arrows-chevron-left:before { content: "\d452"; }
.c-icon-outline-arrows-chevron-right:before { content: "\d454"; }
.c-icon-outline-arrows-chevron-selector-vertical:before { content: "\d457"; }
.c-icon-outline-arrows-chevron-up:before { content: "\d458"; }
.c-icon-solid-arrows-arrow-down:before { content: "\b421"; }
.c-icon-solid-arrows-arrow-up:before { content: "\b447"; }

/* arrows-solid */
@font-face {
  font-family: 'icon-arrows-solid';
  src:
    url('./arrows-solid/icomoon.ttf') format('truetype'),
    url('./arrows-solid/icomoon.woff') format('woff'),
    url('./arrows-solid/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-solid-arrows"] {
  font-family: 'icon-arrows-solid' !important;
}

.c-icon-solid-arrows-arrow-left:before { content: "\b424"; }
.c-icon-solid-arrows-arrow-right:before { content: "\b433"; }
.c-icon-solid-arrows-chevron-down:before { content: "\b450"; }
.c-icon-solid-arrows-chevron-left:before { content: "\b452"; }
.c-icon-solid-arrows-chevron-right:before { content: "\b454"; }
.c-icon-solid-arrows-chevron-up:before { content: "\b458"; }

/* communication-outline */
@font-face {
  font-family: 'icon-communication-outline';
  src:
    url('./communication-outline/icomoon.ttf') format('truetype'),
    url('./communication-outline/icomoon.woff') format('woff'),
    url('./communication-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-communication"] {
  font-family: 'icon-communication-outline' !important;
}

.c-icon-outline-communication-message-text-square-02:before { content: "\f341"; }
.c-icon-outline-communication-annotation-check:before { content: "\f303"; }

/* finance&ecommerce-solid */
@font-face {
  font-family: 'icon-finance&ecommerce-solid';
  src:
    url('./finance&ecommerce-solid/icomoon.ttf') format('truetype'),
    url('./finance&ecommerce-solid/icomoon.woff') format('woff'),
    url('./finance&ecommerce-solid/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-solid-finance"] {
  font-family: 'icon-finance&ecommerce-solid' !important;
}

.c-icon-solid-finance-credit-card-up:before { content: "\b228"; }

/* general-outline */
@font-face {
  font-family: 'icon-general-outline';
  src:
    url('./general-outline/icomoon.ttf') format('truetype'),
    url('./general-outline/icomoon.woff') format('woff'),
    url('./general-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-general"] {
  font-family: 'icon-general-outline' !important;
}

.c-icon-outline-general-check-circle:before { content: "\c822"; }
.c-icon-outline-general-check-done-01:before { content: "\c821"; }
.c-icon-outline-general-copy-01:before { content: "\c832"; }
.c-icon-outline-general-copy-02:before { content: "\c833"; }
.c-icon-outline-general-download-02:before { content: "\c846"; }
.c-icon-outline-general-edit-05:before { content: "\c855"; }
.c-icon-outline-general-info-circle:before { content: "\c885"; }
.c-icon-outline-general-filter-lines:before { content: "\c862"; }
.c-icon-outline-general-link-03:before { content: "\c893"; }
.c-icon-outline-general-link-external-02:before { content: "\c899"; }
.c-icon-outline-general-minus:before { content: "\c919"; }
.c-icon-outline-general-pin-02:before { content: "\c926"; }
.c-icon-outline-general-plus:before { content: "\c928"; }
.c-icon-outline-general-plus-circle:before { content: "\c929"; }
.c-icon-outline-general-save-01:before { content: "\c931"; }
.c-icon-outline-general-settings-01:before { content: "\c989"; }
.c-icon-outline-general-tool-02:before { content: "\c967"; }
.c-icon-outline-general-trash-03:before { content: "\c972"; }
.c-icon-outline-general-x-circle:before { content: "\c981"; }
.c-icon-outline-general-x-close:before { content: "\c982"; }
.c-icon-outline-general-settings-04:before { content: "\c939"; }

/* general-solid */
@font-face {
  font-family: 'icon-general-solid';
  src:
    url('./general-solid/icomoon.ttf') format('truetype'),
    url('./general-solid/icomoon.woff') format('woff'),
    url('./general-solid/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-solid-general"] {
  font-family: 'icon-general-solid' !important;
}

.c-icon-solid-general-check-circle:before { content: "\e915"; }
.c-icon-solid-general-dots-vertical:before { content: "\e92d"; }
.c-icon-solid-general-pin-02:before { content: "\e989"; }
.c-icon-solid-general-plus:before { content: "\e98b"; }
.c-icon-solid-general-translate-01:before { content: "\e9b8"; }
.c-icon-solid-general-x-close:before { content: "\e9c8"; }
.c-icon-solid-general-eye:before { content: "\e93c"; }

/* images-outline */
@font-face {
  font-family: 'icon-images-outline';
  src:
    url('./images-outline/icomoon.ttf') format('truetype'),
    url('./images-outline/icomoon.woff') format('woff'),
    url('./images-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-images"] {
  font-family: 'icon-images-outline' !important;
}

.c-icon-outline-images-image-plus:before { content: "\f518"; }

/* layout-outline */
@font-face {
  font-family: 'icon-layout-outline';
  src:
    url('./layout-outline/icomoon.ttf') format('truetype'),
    url('./layout-outline/icomoon.woff') format('woff'),
    url('./layout-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-layout"] {
  font-family: 'icon-layout-outline' !important;
}

.c-icon-outline-layout-columns-03:before { content: "\d014"; }
.c-icon-outline-layout-layout-alt-01:before { content: "\d041"; }

/* maps&travel-outline */
@font-face {
  font-family: 'icon-maps-outline';
  src:
    url('./maps&travel-outline/icomoon.ttf') format('truetype'),
    url('./maps&travel-outline/icomoon.woff') format('woff'),
    url('./maps&travel-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-maps"] {
  font-family: 'icon-maps-outline' !important;
}

.c-icon-outline-maps-bus:before { content: "\d301"; }
.c-icon-outline-maps-flag-02:before { content: "\d303"; }

/* maps&travel-solid */
@font-face {
  font-family: 'icon-maps&travel-solid';
  src:
    url('./maps&travel-solid/icomoon.ttf') format('truetype'),
    url('./maps&travel-solid/icomoon.woff') format('woff'),
    url('./maps&travel-solid/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-solid-maps"] {
  font-family: 'icon-maps&travel-solid' !important;
}

.c-icon-solid-maps-bus:before { content: "\b301"; }

/* media-solid */
@font-face {
  font-family: 'icon-media-solid';
  src:
    url('./media-solid/icomoon.ttf') format('truetype'),
    url('./media-solid/icomoon.woff') format('woff'),
    url('./media-solid/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-solid-media"] {
  font-family: 'icon-media-solid' !important;
}

.c-icon-solid-media-youtube:before { content: "\c4108"; }
.c-icon-solid-media-mouse:before { content: "\c447"; }

/* files-outline */
@font-face {
  font-family: 'icon-files-outline';
  src:
    url('./files-outline/icomoon.ttf') format('truetype'),
    url('./files-outline/icomoon.woff') format('woff'),
    url('./files-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-files"] {
  font-family: 'icon-files-outline' !important;
}

.c-icon-outline-files-file-02:before { content: "\f409"; }

/* time-outline */
@font-face {
  font-family: 'icon-time-outline';
  src:
    url('./time-outline/icomoon.ttf') format('truetype'),
    url('./time-outline/icomoon.woff') format('woff'),
    url('./time-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-time"] {
  font-family: 'icon-time-outline' !important;
}

.c-icon-outline-time-calendar:before { content: "\f006"; }
.c-icon-outline-time-alarm-clock:before { content: "\f001"; }

/* time-solid */
@font-face {
  font-family: 'icon-time-solid';
  src:
    url('./time-solid/icomoon.ttf') format('truetype'),
    url('./time-solid/icomoon.woff') format('woff'),
    url('./time-solid/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-solid-time"] {
  font-family: 'icon-time-solid' !important;
}

.c-icon-solid-time-calendar:before { content: "\c006"; }
.c-icon-solid-time-alarm-clock:before { content: "\c001"; }

/* users-outline */
@font-face {
  font-family: 'icon-users-outline';
  src:
    url('./users-outline/icomoon.ttf') format('truetype'),
    url('./users-outline/icomoon.woff') format('woff'),
    url('./users-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-users"] {
  font-family: 'icon-users-outline' !important;
}

.c-icon-outline-users-users-02:before { content: "\d523"; }

/* users-solid */
@font-face {
  font-family: 'icon-users-solid';
  src:
    url('./users-solid/icomoon.ttf') format('truetype'),
    url('./users-solid/icomoon.woff') format('woff'),
    url('./users-solid/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-solid-users"] {
  font-family: 'icon-users-solid' !important;
}

.c-icon-solid-users-user-circle:before { content: "\b513"; }

/* ecommerce-outline */
@font-face {
  font-family: 'icon-ecommerce-outline';
  src:
    url('./ecommerce-outline/icomoon.ttf') format('truetype'),
    url('./ecommerce-outline/icomoon.woff') format('woff'),
    url('./ecommerce-outline/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^="c-icon-outline-ecommerce"] {
  font-family: 'icon-ecommerce-outline' !important;
}
.c-icon-outline-ecommerce-shopping-cart-01:before {
  content: "\e90a";
}
