.p-reservationsCreateDetail__section {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservationsCreateDetail__section__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsCreateDetail__section__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-reservationsCreateDetail__section__ic {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-reservationsCreateDetail__section__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-reservationsCreateDetail__section__body {
  border-top: 1px solid var(--gray200);
  padding: 24px;
  display: none;
}
.p-reservationsCreateDetail__section__body.is-active {
  display: block;
}

@media screen and (max-width: 768px) {
  .p-reservationsCreateDetail__section__header {
    padding: 16px;
  }
  .p-reservationsCreateDetail__section__body {
    padding: 16px;
  }
}

/* product */
.p-product {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-product > div:last-child {
  margin-left: auto;
}
.p-product__pic {
  width: 68px;
  height: 68px;
  margin-right: 16px;
  flex-shrink: 0;
}
.p-product__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-product__body {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-product__actions {
  margin-left: 16px;
  flex-shrink: 0;
}

/* join */
.p-join {
}
.p-join__top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-join__top__left {
}
.p-join__top__left__txt {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray700);
}
.p-join__top__right {
  margin-left: auto;
  flex-shrink: 0;
}

.p-join__item {
  margin-top: 16px;
}
.p-join__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 4px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.p-join__item__ttl > *:not(:last-child) {
  margin-right: 4px;
}
.p-join__item__ttl > p {
  margin-bottom: 0;
}
.p-join__item__body {
}
.p-join__item__body__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.p-join__item__body__flex__item {
  width: calc((100% - 32px) / 3);
}
.p-join__item__body__flex__item:not(:nth-child(3n)) {
  margin-right: 16px;
}
@media screen and (max-width: 375px) {
  .p-join__item__body__flex__item {
    width: 100%;
  }
  .p-join__item__body__flex__item:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .p-join__item__body__flex__item:not(:nth-child(1)) {
    margin-top: 16px;
  }
}

/* basic */
.p-basic {
}
.p-basic__item:not(:first-child) {
  margin-top: 16px;
}
.p-basic__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 4px !important;
}
.p-basic__item__body {
}

/* spot */
.p-spot {
}
.p-spot__item:not(:first-child) {
  margin-top: 16px;
}
.p-spot__item__ttl,
.p-spot__item__flex__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 4px !important;
}
.p-spot__item__body {
}
.p-spot__item__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-spot__item__flex__item:first-child {
  width: 320px;
  margin-right: 16px;
}
.p-spot__item__flex__item:nth-child(2) {
  width: 216px;
}
@media screen and (max-width: 375px) {
  .p-spot__item__flex__item:first-child {
    width: 100%;
  }
  .p-spot__item__flex__item:last-child {
    width: 128px;
    flex-shrink: 0;
  }
}

/* option */
.p-option {
}
.p-option__note {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--error700);
  border: 1px solid var(--error200);
  background-color: var(--error50);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-xs);
  padding: 16px;
  /* Override semantic */
  margin-bottom: 16px !important;
}
.p-option__table {
}
.p-option__table__item {
}
.p-option__table__item:not(:first-child) {
  margin-top: 16px;
}
.p-option__table__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 4px !important;
}
.p-option__table__item__body {
}
.p-option__table__item__note {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 4px;
}

/* application */
.p-application {
}
.p-application__header {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-application__header__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-application__header__actions {
  margin-left: auto;
  flex-shrink: 0;
}
.p-application__item {}
.p-application__item:not(:first-child) {
  margin-top: 16px;
}
.p-application__item__ttl,
.p-application__item__flex__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 4px !important;
}
.p-application__item__body {
}
.p-application__item__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-application__item__flex__item:first-child {
  width: 320px;
  margin-right: 16px;
}
.p-application__item__flex__item:nth-child(2) {
  width: 216px;
}
@media screen and (max-width: 375px) {
  .p-application__item__flex__item:first-child {
    width: 100%;
  }
  .p-application__item__flex__item:last-child {
    width: 128px;
    flex-shrink: 0;
  }
}

.p-modal {
}
.p-modal__item:not(:first-child) {
  margin-top: 16px;
}
.p-modal__item__ttl,
.p-modal__item__flex__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 4px !important;
}
.p-modal__item__body {
}

.p-reservationsCreateDetail__infoFrame {
}
.p-reservationsCreateDetail__info {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 24px;
}
.p-reservationsCreateDetail__info__item {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-reservationsCreateDetail__info__item:not(:first-child) {
  border-top: 1px solid var(--gray200);
  margin-top: 20px;
  padding-top: 20px;
}
.p-reservationsCreateDetail__info__item__ttl {
  color: var(--gray600);
  /* Override semantic */
  margin-bottom: 4px !important;
}
.p-reservationsCreateDetail__info__item__body {
}
.p-reservationsCreateDetail__info__actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.p-reservationsCreateDetail__info__actions a {
  width: 100%;
}
.p-reservationsCreateDetail__menu {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .p-reservationsCreateDetail__info {
    width: 100%;
    height: 400px;
    border-radius: 0;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 100%;
    left: 0;
    transition: top 0.4s ease;
  }
  .p-reservationsCreateDetail__info.is-active {
    top: calc(100% - 400px);
  }
  .p-reservationsCreateDetail__info__header {
    width: 100%;
    height: 64px;
    background-color: var(--white);
    border-bottom: 1px solid var(--gray200);
    padding: 0 24px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
  }
  .p-reservationsCreateDetail__info__header p {
    font-size: var(--text-lg-size);
    font-weight: var(--text-bold);
    line-height: var(--text-lg-height);
  }
  .p-reservationsCreateDetail__info__body {
    height: 100%;
    padding: 20px 24px 104px 24px;
    /* overflow-y: auto; */
  }
  .p-reservationsCreateDetail__info__actions {
    width: 100%;
    height: 80px;
    border-top: 1px solid var(--gray200);
    background-color: var(--white);
    padding: 0 24px;
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    position: fixed;
    left: 0;
    bottom: 0;
  }
  .p-reservationsCreateDetail__info__actions a {
    width: fit-content;
  }
  .p-reservationsCreateDetail__info__actions > * {
    margin-left: 12px;
  }
  .p-reservationsCreateDetail__sectionSpBottom {
    margin-bottom: 48px;
  }
}

/* modal */
.p-reservationConfirm {
}
.p-reservationConfirm__item {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-reservationConfirm__item:not(:first-child) {
  margin-top: 16px;
}
.p-reservationConfirm__item__ttl {
  font-weight: var(--text-semibold);
  /* color: var(--gray700); */
  /* Override semantic */
  margin-bottom: 4px !important;
}
.p-reservationConfirm__item__body {
}

.p-reservationsCreateDetail__spMenu {
  width: 100%;
  height: 68px;
  margin-bottom: 12px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}
.p-reservationsCreateDetail__spMenu__inner {
  width: calc(100% + 32px);
  padding: 12px 16px;
  position: relative;
  left: -16px;
  transition: all 0.2s ease;
}
.p-reservationsCreateDetail__spMenu__inner.is-active {
  background-color: var(--white);
  box-shadow: var(--shadow-md);
}

.p-errorBox {
  margin-top: 24px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--error700);
  border: 1px solid var(--error200);
  background-color: var(--error50);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-xs);
  padding: 16px;
}
.p-errorBox__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
}
.p-errorBox__body {
  margin: 8px 16px;
  padding: 0;
}
.p-errorBox__body li {
  list-style-type: initial;
  margin: 2px 0;
  font-size: 1.143em;
}

.p-fareAdjustment__modal {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(3, auto) auto;
  grid-column-gap: 16px;
}

.info-message {
  border: 1px solid #a9d5de;
  border-radius: 5px;
  background-color: #f8ffff;
  color: #276f86;
  padding: 1em 1.5em;
  margin: 1em 0;
}

.message {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  background-color: #f8f8f9;
  padding: 1em 1.5em;
  margin: 1em 0;
}