/* Note: originally these properties were defined in 'ReservationDetailsSection.module.css' */
.p-primary__select {
  position: relative;
}
.p-primary__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.is-active {
  display: block;
}
.p-primary__select__menu__item {
  position: relative;
  cursor: pointer;
}
.p-primary__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-primary__select__menu__item > a,
.p-primary__select__menu__item > p {
  padding: 8px 10px;
  width: 100%;
  display: block;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}