.p-siteControlWidget {
}
.p-pane {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.p-pane__left {
  width: calc(100% - 496px);
}
.p-pane__right {
  width: 480px;
  margin-left: 16px;
}
.p-section {
  background-color: var(--white);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-xl);
  width: 100%;
  padding: 16px;
}
.p-preview__header {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.p-preview {
  border: 1px solid var(--gray100);
  background-color: var(--gray50);
  border-radius: var(--radius-sm);
}
.p-preview__pc {
  position: relative;
  min-height: 560px;
}
.p-preview__sp {
  position: relative;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-sm);
  width: 375px;
  height: 667px;
  margin: 24px auto;
}
.p-topButtons {
  display: flex;
  justify-content: flex-end;
}
.p-topButtons > button:not(:first-child) {
  margin-left: 8px;
}
.p-board {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.p-board li {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 25%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.p-list {
}
.p-list__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-list__item:not(:first-child) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--gray200);
}
.p-list__item__ttl {
  width: 150px;
  color: var(--gray500);
  display: flex;
  justify-content: flex-start;
}
.p-list__item__ttl2 {
  width: 180px;
  color: var(--gray500);
  display: flex;
  justify-content: flex-start;
}
.p-list__item__ttl__withBadge {
}
.p-list__item__ttl > *:not(:first-child) {
  margin-left: 8px;
}
.p-list__item__body {
  width: calc(100% - 150px);
}
.p-list__item__body2 {
  width: calc(100% - 180px);
}
.p-stopDisplay {
  display: flex;
  column-gap: 6px;
}
.p-stopDisplay__full {
  width: 100%;
}
.p-stopDisplay__sixty {
  width: 60%;
}
.p-stopDisplay__twenty {
  width: 20%;
}
.p-schedule {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.p-schedule__full {
  width: 100%;
}
.p-schedule__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.p-schedule__item__condition {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.p-schedule__item__conditionDetails {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.p-schedule__item__type {
  width: 100%;
}
.p-schedule__date__p {
  margin: 0;
}
.p-schedule__daysOfWeek {
  display: flex;
  align-items: center;
  height: 46px;
  width: 100%;
}
.p-schedule__and {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--gray500);
}
.p-schedule__and::before,
.p-schedule__and::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: var(--gray200);
}
.p-schedule__and::before {
  margin-right: 10px;
}
.p-schedule__and::after {
  margin-left: 10px;
}
.p-list__item.column {
  flex-direction: column;
  align-items: flex-start;
}
.p-list__item__group {
  width: 100%;
  display: flex;
  align-items: center;
}
.p-list__item__group__ttl {
  width: 100%;
  color: var(--gray800);
}
.p-list__item__group__body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__item__group__body:not(:first-of-type) {
  margin-top: 12px;
}
.p-image ul li div img {
  object-fit: contain;
}

.p-toggle_previewView {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}
.p-toggle_previewView li {
  width: fit-content;
  width: 40px;
  height: 40px;
  color: var(--gray400);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.p-toggle_previewView li:not(:last-child) {
  border-right: 1px solid var(--gray200);
}
.p-toggle_previewView li.is-active {
  color: var(--primary700);
  font-weight: 700;
}
.p-toggle_previewView li:first-child.is-active {
  border: 1px solid var(--primary700);
  border-top-left-radius: var(--radius-sm);
  border-bottom-left-radius: var(--radius-sm);
}
.p-toggle_previewView li:last-child.is-active {
  border: 1px solid var(--primary700);
  border-top-right-radius: var(--radius-sm);
  border-bottom-right-radius: var(--radius-sm);
}
.p-toggle_previewView li i {
  font-size: 20px;
}

.p-button__addCondition {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 46px;
}
.p-button__addCondition i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
.p-button__deleteCondition {
  display: flex;
  align-items: center;
}
.p-toggle {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
}
.p-toggle p {
  color: var(--gray500);
}

@media screen and (max-width: 768px) {
  .p-board li {
    flex: 1 1 50%;
  }
  .p-list__item__ttl {
    width: 120px;
    display: flex;
    flex-direction: column;
  }
  .p-list__item__ttl > *:not(:first-child) {
    margin-top: 4px;
    margin-left: 0;
  }
  .p-list__item__body {
    width: calc(100% - 150px);
  }
  .p-list__item__ttl2 {
    width: 120px;
  }
  .p-list__item__ttl__withBadge {
    display: flex;
    flex-direction: column;
  }
  .p-list__item__ttl__withBadge:first-child {
    margin-right: 0;
    margin-bottom: 2px;
  }
  .p-list__item__body2 {
    width: calc(100% - 120px);
  }
  .p-stopDisplay {
    flex-direction: column;
    column-gap: 0;
    row-gap: 6px;
  }
  .p-stopDisplay__full,
  .p-stopDisplay__sixty,
  .p-stopDisplay__twenty {
    width: 100%;
  }
  .p-schedule {
    flex-direction: column;
  }
  .p-schedule__item {
    flex-direction: column;
  }
  .p-schedule__item__condition {
    flex-direction: column;
  }
  .p-schedule__item__conditionDetails {
    flex-direction: column;
  }
  .p-schedule__date__p {
    display: none;
  }
  .p-schedule__daysOfWeek {
    height: 100%;
  }

  .p-pane {
    flex-direction: column;
  }
  .p-pane__left,
  .p-pane__right {
    width: 100%;
  }
  .p-pane__right {
    margin-left: 0;
    margin-top: 16px;
  }
  .p-preview__sp {
    width: 100%;
    margin: 0;
    border: none;
  }
}
